import { defineStore } from 'pinia';
import { isObject } from '/@/utils/is';

interface MsgInterface {
  msg: Object;
}
export const useWsStore = defineStore({
  id: 'app-ws',
  state: (): MsgInterface => ({
    msg: null,
  }),
  getters: {
    getWsInfo(): object {
      return this.msg;
    },
  },
  actions: {
    setWsInfo(info: object) {
      this.msg = isObject(info) ? info : {};
    },
  },
});
