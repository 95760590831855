/**
 * @Description: 文本转语音
 * @author hu_jie
 * @date 2023/8/31
 */
// 封装公共方法的函数
export function playTextToSpeech(text: string) {
  // 检查浏览器是否支持 SpeechSynthesis API
  if ('speechSynthesis' in window) {
    // 创建一个新的 SpeechSynthesisUtterance 对象
    const utterance = new window.SpeechSynthesisUtterance(text);

    // 播放计数，初始值为0
    let playCount = 0;

    // 监听 SpeechSynthesisUtterance 的 end 事件，当音频播放结束时触发
    utterance.addEventListener('end', () => {
      playCount++;

      // 当播放次数小于3时，继续播放
      if (playCount < 3) {
        window.speechSynthesis.speak(utterance);
      }
    });

    // 开始播放
    window.speechSynthesis.speak(utterance);
  } else {
    console.log('SpeechSynthesis API is not supported in this browser.');
  }
}
