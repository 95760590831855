import Stomp from 'stompjs';
import SockJS from 'sockjs-client/dist/sockjs.min.js';
import { tryOnUnmounted } from '@vueuse/core';
import { App, getCurrentInstance } from 'vue';
import { useGlobSetting } from '/@/hooks/setting';
import { getToken } from '/@/utils/auth';
import mitt from '/@/utils/mitt';

const globSetting = useGlobSetting();
export declare interface StompClient {
  subscribe(topic: string, callback: (msg: any) => void): void;
  unsubscribe(topic: string): void;
}

export const emitter = mitt();
function createStomp() {
  // const apiUrl = 'http://127.0.0.1:8000';
  const subscribes = {};

  let stompClient;
  let socketTimer;

  // let resolve;
  // let reject;
  //
  // const promise = new Promise(function (resolve1, reject1) {
  //   reject = reject1;
  //   resolve = resolve1;
  // });

  function _initWebsocket() {
    const apiUrl = globSetting.apiUrl;
    const socket = new SockJS(apiUrl + '/websocketStomp');
    stompClient = Stomp.over(socket);
    stompClient.debug = null;
    stompClient.connect(
      {
        Author: '头部信息',
        token: getToken(),
      }, // 传递token
      () => {
        console.log('stompClient connected!!!', apiUrl);
        emitter.emit('reconnect');
      },
      (err) => {
        // 监听报错信息，手动发起重连
        console.log('socketErr: ', err);
        if (socketTimer) {
          clearInterval(socketTimer);
        }
        // 10s后重新连接一次
        socketTimer = setTimeout(() => {
          console.log('开始重联');
          _initWebsocket();
          // _closeWebsocket();
        }, 10000);
      },
    );
    // stompClient.heartbeat.outgoing = 5000;
    // 若使用STOMP 1.1 版本，默认开启了心跳检测机制（默认值都是10000ms）
    // stompClient.heartbeat.incoming = 0;
    // 客户端不从服务端接收心跳包
  }

  function _closeWebsocket() {
    console.log('start close websocket connect ...');
    try {
      if (stompClient !== null) {
        stompClient.disconnect(() => {
          console.log('关闭连接');
          console.log('1');
        });
      }
      if (socketTimer) {
        clearInterval(socketTimer);
      }
    } catch (err) {
      console.error(err);
    }
  }

  function subscribe(topic, callback) {
    if (subscribes[topic]) {
      // throw new Error(`${topic} 已经订阅`);
      unsubscribe(topic);
    }

    const subInfo = stompClient.subscribe(topic, (res) => {
      const msg = JSON.parse(res.body);
      callback(msg);
    });

    console.log('subscribe: ', topic);

    subscribes[topic] = subInfo;
  }

  function unsubscribe(topic) {
    const subInfo = subscribes[topic];
    console.log('subInfo: ', subInfo);
    if (subInfo) {
      subInfo.unsubscribe();
      delete subscribes[topic];
      console.log('unsubscribe: ', topic);
    }
  }

  function install(app: App) {
    app.config.globalProperties['$stompClient1'] = {
      subscribe,
      unsubscribe,
    } as StompClient;
  }

  _initWebsocket();

  tryOnUnmounted(_closeWebsocket);

  return { install };
}

export function setupStomp(app: App) {
  const stomp = createStomp();
  app.use(stomp);
}

export function useStompClient(): StompClient {
  const app = getCurrentInstance();
  return app?.appContext.config.globalProperties['$stompClient1'];
}
