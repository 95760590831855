import { createVNode } from 'vue';
import { Modal } from 'ant-design-vue';
import { ExclamationCircleOutlined } from '@ant-design/icons-vue';
import { useUserStore } from '/@/store/modules/user';

// 登录弹窗单例模式
const showSessionExpiredModal = (function () {
  let vm;
  let userStore;
  return function () {
    if (!userStore) userStore = useUserStore();

    if (vm != null && vm.isDestroy === true) {
      vm = null;
    }
    if (!vm) {
      // Modal.destroyAll;
      vm = Modal.confirm({
        title: '会话过期',
        icon: createVNode(ExclamationCircleOutlined),
        content: '当前会话已过期，请重新登录。',
        okText: '登录',
        cancelText: '取消',
        keyboard: false,
        visible: false,
        onOk() {
          vm.isDestroy = true;
          vm.destroy();
          userStore.logout(true);
        },
        onCancel() {
          vm.isDestroy = true;
          console.log('onCancel');
        },
        afterClose() {
          vm.isDestroy = true;
          console.log('afterClose');
        },
      });
      // console.log('vm', vm)
    }

    vm.update({
      visible: true,
    });
    return vm;
  };
})();

export default showSessionExpiredModal;
