import { onBeforeUnmount, onMounted } from 'vue';
import { useGo } from '/@/hooks/web/usePage';
import { useWsStore } from '/@/store/modules/ws';
import { emitter, useStompClient } from '/@/utils/wx/tkSetupStomp';
import { useUserStore } from '/@/store/modules/user';
import { DEVICE_TOPIC } from '/@/settings/wsSetting';
import { isObject } from '/@/utils/is';
import { Modal } from 'ant-design-vue';
import { playTextToSpeech } from '/@/utils/Speech';

export function useSubscribe() {
  const go = useGo();
  const wsStore = useWsStore();
  const stompClient = useStompClient();
  const userStore = useUserStore();
  const communityId = Number(userStore.getUserInfo?.communityId);
  const subscribe = () => {
    if (communityId) {
      stompClient.subscribe(`${DEVICE_TOPIC}${communityId}`, (res) => {
        if (isObject(res)) {
          wsStore.setWsInfo(res);
          // 跌倒
          if (res.fall) {
            Modal.destroyAll();
            playTextToSpeech(
              `${res.room.buildNo}号楼${res.room.unitNo}单元${res.room.name}${
                res.room.name.includes('房间') ? '' : '房间'
              }发生跌倒事件，请尽快处理`,
            );
            Modal.warning({
              title: '消息提示',
              content: `${res.room.buildNo}号楼${res.room.unitNo}单元${res.room.name}出现跌倒！`,
              okText: '前往',
              okButtonProps: {
                danger: true,
                type: 'primary',
              },
              wrapClassName: 'global-ws-modal',
              onOk() {
                go(`/community/room/list?buildId=${res.room.buildNo}&roomId=${res.room.id}`, true);
              },
            });
          }
        }
      });
    }
  };
  onMounted(() => {
    subscribe();
    emitter.on('reconnect', subscribe);
  });
  onBeforeUnmount(() => {
    emitter.off('reconnect', subscribe);
  });
}

export function useUnSubscribe() {
  if (window.localStorage && window.localStorage.getItem('communityId')) {
    const communityId = Number(window.localStorage.getItem('communityId'));
    const stompClient = useStompClient();
    onMounted(() => {
      stompClient.unsubscribe(`${DEVICE_TOPIC}${communityId}`);
    });
  }
}
