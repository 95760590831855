import { App } from 'vue';
import AMapLoader from '@amap/amap-jsapi-loader';

export async function setupAmap(app: App<Element>) {
  // @ts-ignore
  window._AMapSecurityConfig = {
    securityJsCode: 'd3722654c8408a35050b472e17cc2ce2',
  };
  const amap = await AMapLoader.load({
    key: '236e524881c3a32ae678647851f20208', // 申请好的Web端开发者Key，首次调用 load 时必填
    version: '2.0', // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
    plugins: [],
    Loca: {
      // 是否加载 Loca， 缺省不加载
      version: '2.0.0', // Loca 版本，缺省 1.3.2
    },
  });
  app.provide('amap', amap);
}
