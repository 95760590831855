import { AppRouteRecordRaw } from '/@/router/types';
import { REDIRECT_NAME } from '/@/router/constant';

const YlModule: AppRouteRecordRaw[] = [
  {
    path: '/wx',
    name: 'WxHome',
    component: () => import('/@/views/yl/country/index.vue'),
    meta: {
      title: '',
    },
  },
  {
    path: '/community',
    name: 'CommunityIndex',
    component: () => import('/@/views/yl/community/index.vue'),
    meta: {
      title: '',
      ignoreKeepAlive: false,
    },
  },
  {
    path: '/community/room/list',
    name: 'CommunityRoomListIndex',
    component: () => import('/@/views/yl/room-list/index.vue'),
    meta: {
      title: '',
    },
  },
  {
    path: '/community/device',
    name: 'DeviceListIndex',
    component: () => import('/@/views/yl/device-list/index.vue'),
    meta: {
      title: '',
    },
  },
  {
    path: '/community/device/detail',
    name: 'DeviceDetailData',
    component: () => import('/@/views/yl/device-data/index.vue'),
    meta: {
      title: '',
    },
  },
  {
    path: '/community/info',
    name: 'InfoManagement',
    component: () => import('/@/views/yl/info-management/index.vue'),
    meta: {
      title: '',
    },
    redirect: '/community/info/user-management',
    children: [
      {
        path: 'user-management',
        name: 'UserManagement',
        component: () => import('/@/views/yl/user-management/index.vue'),
        meta: {
          title: '',
        },
      },
      {
        path: 'device-management',
        name: 'DeviceManagement',
        component: () => import('/@/views/yl/device-management/index.vue'),
        meta: {
          title: '',
        },
      },
      {
        path: 'room-management',
        name: 'RoomManagement',
        component: () => import('/@/views/yl/room-management/index.vue'),
        meta: {
          title: '',
        },
      },
    ],
  },
];

export default YlModule;
